import React from 'react'
import 'normalize.css/normalize.css'
import '../../assets/styles/fonts.css'
import './Layout.css'

export default ({children}) => (
  <div>
    {children}
  </div>
)
