import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import FirstScreen from '../components/FirstScreen/FirstScreen'
import About from '../components/About/About'
import Principles from '../components/Principles/Principles'
import Opinions from '../components/Opinions/Opinions'
import Jobs from '../components/Jobs/Jobs'
import { Reasons } from '../components/Reasons/Reasons'
import CallbackForm from '../components/CallbackForm/CallbackForm'
import Faq from '../components/Faq/Faq'
import ContactUs from '../components/ContactUs/ContactUs'
import { initSentry } from '../utils/sentry'
import { useSaveUTMToCookies } from '../utils/useSaveUTMToCookies'

if (process.env.NODE_ENV === 'production') {
  initSentry()
}

const App = () => {
  useSaveUTMToCookies()

  return (
    <Layout>
      <Helmet>
        <title>Карьера в Appbooster — продвижение мобильных приложений</title>
        <meta name="description" content="Присоединяйся к Appbooster — агентству из ТОП-3 в мобильном маркетинге. Фронтенд, бэкенд, дизайн, продажи, медиабаинг и другие открытые позиции." />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon-192.png" />
        <link rel="icon" type="image/png" sizes="120x120" href="/favicon-120.png" />
        <link rel="icon" type="image/png" sizes="60x60" href="/favicon-60.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Helmet>
      <FirstScreen />
      <About />
      <Principles />
      <Opinions />
      <Reasons />
      <Jobs/>
      <CallbackForm />
      <Faq />
      <ContactUs />
    </Layout>
  );
};

export default App
