import React, { useEffect, useState } from "react"
import clsx from "clsx"
import axios from "axios"
import * as qs from "query-string"
import "./CallbackForm.css"
import Button from "../_UI/Button/Button"

export default () => {
  const fileInput = React.createRef()
  const fileInputLabel = React.createRef()
  const form = React.createRef()
  const [isAttached, setIsAttached] = useState(false)
  const [feedbackMsg, setFeedbackMsg] = useState(null)
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [privacyConfirmed, setPrivacyConfirmed] = useState(false)
  const [formData, setFormData] = useState({
    "form-name": "CallbackForm", // required by Netlify
    email: "", // honeypot
    name: "",
    contact: "",
    about: "",
    linkToResume: "Файл резюме не приложен",
  })

  const onClickAttachHandler = () => {
    fileInput.current.click()
  }

  const onChangeAttachHandler = e => {
    if (e.target.files[0] !== undefined) {
      setIsAttached(true)
      fileInputLabel.current.textContent = e.target.files[0].name
    } else {
      setIsAttached(false)
      fileInputLabel.current.textContent = ""
    }
  }

  const clearFileInput = e => {
    e.preventDefault()
    setIsAttached(false)
    fileInput.current.value = ""
    fileInputLabel.current.textContent = ""
  }

  const onChangeHandler = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmitHandler = e => {
    e.preventDefault()
    const form = e.target

    if (!form.email.value.length) {
      if (form.file.files[0]) {
        const options_s3 = {
          url: "/.netlify/functions/aws-s3-upload",
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          data: form.file.files[0].name,
        }

        axios(options_s3)
          .then(response => {
            return response.data
          })
          .then(data => {
            return {
              method: "PUT",
              url: data.uploadUrl,
              fields: [],
              headers: [],
            }
          })
          .then(obj => {
            const opts = {
              method: obj.method,
              body: form.file.files[0],
            }
            fetch(obj.url, opts).then(response => {
              const url = response.url
              setFormData({
                ...formData,
                linkToResume: url.slice(0, url.indexOf("?")),
              })
              setReadyToSubmit(true)
            })
          })
      } else {
        setReadyToSubmit(true)
      }
    } else {
      console.log("spam")
    }
  }

  const generateFeedbackMsg = status => {
    switch (status) {
      case "done":
        const rand = Math.round(Math.random() * 2)
        const links = [
          <a
            href="https://appbooster.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            почитай наш блог
          </a>,
          <a
            href="https://soundcloud.com/appbooster"
            target="_blank"
            rel="noopener noreferrer"
          >
            послушай наш подкаст
          </a>,
          <a
            href="https://instagram.com/appbooster"
            target="_blank"
            rel="noopener noreferrer"
          >
            подпишись на наш Instagram
          </a>,
        ]
        return (
          <div>
            <p>
              Спасибо! Мы уже распечатали твоё резюме и положили на стол
              начальству. Держим за тебя кулачки 🤞😊
            </p>
            <p>А пока отклик в обработке, {links[rand]}</p>
          </div>
        )
      case "fail":
        return (
          <div>
            <p>
              Не удалось отправить отклик 😔 Наши разработчики уже в курсе
              ошибки и скоро всё исправят, а пока свяжись с Антоном в телеграме:{" "}
              <a href="tg://resolve?domain=a_agapov">@a_agapov</a>
            </p>
          </div>
        )
    }
  }

  useEffect(() => {
    if (feedbackMsg) {
      setTimeout(() => {
        setFeedbackMsg("")
        setPrivacyConfirmed(false)
      }, 15000)
    }
  }, [feedbackMsg])

  useEffect(() => {
    if (readyToSubmit) {
      const options_netlify = {
        url: window.location.pathname,
        method: form.current.method,
        headers: { "Content-Type": "multipart/form-data" },
        data: qs.stringify(formData),
      }
      axios(options_netlify)
        .then(response => {
          setFeedbackMsg(generateFeedbackMsg("done"))
        })
        .catch(err => {
          setFeedbackMsg(generateFeedbackMsg("fail"))
        })
      form.current.reset()
      setIsAttached(false)
      fileInputLabel.current.textContent = ""
      setFormData({
        ...formData,
        linkToResume: "Файл резюме не приложен",
      })
      setReadyToSubmit(false)
    }
  }, [readyToSubmit])

  return (
    <div className="CallbackForm" id="callbackform">
      <div className="CallbackForm__container">
        <h2 className="title title_h3 CallbackForm__title">
          Не нашли вакансию?
        </h2>
        <h3 className="title title_h2 CallbackForm__subtitle">
          Свяжитесь с&nbsp;нами&nbsp;&mdash; и&nbsp;узнайте первым, когда она
          откроется:
        </h3>
        <form
          className="form"
          name="CallbackForm"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="email"
          onSubmit={onSubmitHandler}
          ref={form}
        >
          <div
            className={clsx("form__fields", {
              form__fields_hidden: feedbackMsg,
            })}
          >
            <input type="hidden" name="form-name" value="CallbackForm" />
            <input type="hidden" name="email" onChange={onChangeHandler} />{" "}
            {/* honeypot */}
            <input type="hidden" name="linkToResume" />
            <input
              ref={fileInput}
              type="file"
              name="file"
              capture="filesystem"
              className="input-file-hidden"
              onChange={onChangeAttachHandler}
            />
            <div className="form__row">
              <div className="input CallbackForm__input CallbackForm__input_size_20r">
                <input
                  className="input-field"
                  type="text"
                  name="name"
                  id="CallbackForm-name"
                  placeholder="Имя"
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <div className="input  CallbackForm__input CallbackForm__input_size_grow">
                <input
                  className="input-field"
                  type="text"
                  name="contact"
                  id="CallbackForm-link"
                  placeholder="Профиль в соцсети"
                  onChange={onChangeHandler}
                  required
                />
              </div>
            </div>
            <div className="form__row">
              <div className="textarea">
                <textarea
                  className="textarea-field"
                  name="about"
                  id="CallbackForm-text"
                  cols="30"
                  rows="10"
                  placeholder="Расскажите нам, чем вы могли бы быть нам полезны?
                Почему хотите у нас работать?
                Не забудьте оставить ваши контакты."
                  onChange={onChangeHandler}
                  required
                ></textarea>
                <div className="d-flex">
                  <input
                    id="callback-form"
                    className="CallbackForm__checkbox"
                    type="checkbox"
                  />
                  <label
                    htmlFor="callback-form"
                    className="CallbackForm__label"
                    onClick={() => setPrivacyConfirmed(!privacyConfirmed)}
                  >
                    Я даю согласие на обработку моих персональных данных
                  </label>
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="input-file">
                <Button
                  skin={"dark"}
                  className="CallbackForm__btn"
                  ClickHandler={onClickAttachHandler}
                >
                  Прикрепить резюме
                </Button>
                <div
                  className={clsx("input-file__desc-wrapper", {
                    "input-file__desc-wrapper_filled": isAttached,
                  })}
                >
                  <span
                    className="input-file__label"
                    ref={fileInputLabel}
                  ></span>
                  <button
                    className={clsx("input-file__del", {
                      "input-file__del_hidden": !isAttached,
                    })}
                    onClick={clearFileInput}
                  >
                    &times;
                  </button>
                </div>
              </div>
              <Button
                className={"btn_width_fixed CallbackForm__btn"}
                type="submit"
                disabled={!privacyConfirmed}
              >
                Хочу работать у вас!
              </Button>
            </div>
          </div>
          <div
            className={clsx("form__result", {
              form__result_visible: feedbackMsg,
            })}
          >
            {feedbackMsg}
          </div>
        </form>
      </div>
    </div>
  )
}
