import React from "react"
import './MenuMobileBtn.css'

export default (props) => {
  return (
    <button type="button" className="MenuMobileBtn" onClick={props.onClick}>
      <svg className="MenuMobileBtn__icon" width="30" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="10" width="27" height="2" fill="#232E35"/>
        <rect y="17" width="27" height="2" fill="#232E35"/>
        <circle cx="27" cy="3" r="3" fill="#EE366C"/>
      </svg>
    </button>
  )
}
