import React from 'react'
import './Faq.css'

export default () => (
  <div className="Faq">
    <div className="Faq__container">
      <h2 className="title title_h2 Faq__title">Часто задаваемые вопросы</h2>
      <div className="Faq__block">
        <h3 className="title title_h3">Как происходит найм <br/> в вашей компании?</h3>
        <p>Мы проводим отбор в несколько этапов: сначала изучаем ваш отклик, потом приглашаем на интервью. После первого интервью обычно предлагаем тестовое задание и встречаемся ещё раз.</p>
      </div>
      <div className="Faq__block">
        <h3 className="title title_h3">Я послал резюме, <br/> но не получил ответа, как быть?</h3>
        <p>Мы стараемся оперативно отвечать на все отклики, однако для некоторых позиций это может быть затруднительно. Так, если мы не ответили вам в течение 2 недель, то пока не можем предложить интервью.</p>
      </div>
    </div>
  </div>
)
