import React from 'react'
import Logo from '../_UI/Logo/Logo'
import './ContactUs.css'

export default () => (
  <div className="ContactUs">
    <div className="ContactUs__container">
      <div className="ContactUs__left"></div>
      <div className="ContactUs__right">
        <h2 className="title title_h2 ContactUs__title">Свяжитесь с нами</h2>
        <ul className="ContactUs__list">
          <li className="ContactUs__item">
            <a className="ContactUs__list-link" href="mailto:hello@appbooster.com">hello@appbooster.com</a>
          </li>
        </ul>
        <div className="ContactUs__links">
          <Logo />
          <a
            className="ContactUs__link"
            href="https://appbooster.com/"
            target="_blank"
            rel="noopener noreferrer"
          >Основной сайт
          </a>
          <a
            className="ContactUs__link"
            href="https://my.appbooster.com/"
            target="_blank"
            rel="noopener noreferrer"
          >Платформа
          </a>
        </div>
      </div>
    </div>
  </div>
)
