import React from 'react'
import './Logo.css'
import Logo from '../../../assets/images/Logo.png'
import Logo_light from '../../../assets/images/Logo_light.png'

export default (props) => {
  const skins = {
    defalut: Logo,
    light: Logo_light,
  }
  return(
    <a href="https://appbooster.com/" className="Logo">
      <img
        className="Logo__img"
        src={props.skin ? skins[props.skin] : skins.defalut}
        alt="Appbooster logo"/>
    </a>
  )
}
