import React from 'react'
import './About.css'
import Img_1 from '../../assets/images/About__1.jpg'
import Img_2_1 from '../../assets/images/About__2-1.jpg'
import Img_2_2 from '../../assets/images/About__2-2.png'
import Img_3 from '../../assets/images/About__3.jpg'
import Team from '../../assets/images/About__team.jpg'

export default () => (
  <div className="About">
    <div className="About__container">
        <div className="About__block About__block_1">
          <div className="About__desc">
            <h2 className="title title_h2 About__heading">О нас</h2>
            <h3 className="title title_h3 About__title">Appbooster — агентство по продвижению мобильных приложений</h3>
            <p>Входим в ТОП-3 агентств мобильного маркетинга согласно рейтингу Ruward.</p>
          </div>
          <div className="About__pic">
            <img className="About__img" src={Img_1} alt=""/>
          </div>
        </div>
        <div className="About__block About__block_2">
          <div className="About__pic">
            <img className="About__img" src={Img_2_1} alt=""/>
            <img className="About__img-decor" src={Img_2_2} alt=""/>
          </div>
          <div className="About__desc">
            <h3 className="title title_h3 About__title">Работаем с  брендами, стартапами и независимыми разработчиками</h3>
            <p>Спорим, в твоём смартфоне установлено приложение или игра одного или нескольких наших клиентов?</p>
          </div>
        </div>
        <div className="About__block About__block_3">
          <div className="About__desc">
            <h3 className="title title_h3 About__title">В Appbooster любят мобайл</h3>
            <p>Мы постоянно придумываем и реализовываем внутренние проекты, связанные с приложениями. Узнай больше о нас на этой странице.</p>
          </div>
          <div className="About__pic">
            <img className="About__img" src={Img_3} alt=""/>
          </div>
        </div>
    </div>
    <div className="About__team">
      <img src={Team} alt=""/>
    </div>
  </div>
)
