import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AnnaS from '../../assets/images/Opinions__Anna-S.png'
import AnnaCh from '../../assets/images/Opinions__Anna-Ch.png'
import SashaS from '../../assets/images/Opinions__Sasha-S.png'
import DimaK from '../../assets/images/Opinions__Dima-K.png'
import AlyonaP from '../../assets/images/Opinions__Alyona-P.png'
import './Opinions.css'

export default () => (
  <div className="Opinions" id="opinions">
    <h2 className="title title_h2 Opinions__heading">Ребята говорят</h2>
    <Tabs forceRenderTabPanel={true}>
      <TabList className="tab-nav">
        <Tab className="tab-nav__item tab-nav__item_1">
          <span className="tab-nav__label">Анна,<br/>Fullstack-разработчик</span>
        </Tab>
        <Tab className="tab-nav__item tab-nav__item_2">
          <span className="tab-nav__label">Анна,<br/>Менеджер по&nbsp;продажам</span>
        </Tab>
        <Tab className="tab-nav__item tab-nav__item_3">
          <span className="tab-nav__label">Александр,<br/>CTO</span>
        </Tab>
        <Tab className="tab-nav__item tab-nav__item_4">
          <span className="tab-nav__label">Дмитрий,<br/>Frontend-разработчик</span>
        </Tab>
        <Tab className="tab-nav__item tab-nav__item_5">
          <span className="tab-nav__label">Алёна,<br/>HR-директор</span>
        </Tab>
      </TabList>
      <TabPanel className="tab-content__item">
        <div className="Opinions__text-wrapper">
        <div className="Opinions__text">
          <q className="title title_h3 Opinions__title">Не&nbsp;работаем на&nbsp;заказ</q>
          <blockquote className="Opinions__blockquote">
            <p>Я&nbsp;люблю и&nbsp;ценю Appbooster за&nbsp;то, что мы&nbsp;делаем продукт, а&nbsp;не&nbsp;работаем на&nbsp;заказ. Из&nbsp;этого вытекает куча плюсов для меня как для разработчика: гибкость, технически грамотный внутренний заказчик, отсутствие бесполезных задач и&nbsp;неадекватных сроков, быстрая обратная связь, диалог, возможность влиять на&nbsp;спецификации продукта, возможность делать качественно вместо того, чтобы укладываться в&nbsp;бюджет.</p>
            <p>У&nbsp;нас сильная команда, состоящая из&nbsp;профессиональных и&nbsp;увлечённых людей. Здесь нет бюрократии, микроменеджмента и&nbsp;необходимости думать о&nbsp;&laquo;политических маневрах&raquo;.</p>
            <footer className="Opinions__author">Анна Селиванова, Fullstack-разработчик</footer>
          </blockquote>
        </div>
        <div className="Opinions__pic Opinions__pic_1">
          <img className="Opinions__img" src={AnnaS} alt=""/>
        </div>
        </div>
      </TabPanel>
      <TabPanel className="tab-content__item">
        <div className="Opinions__text-wrapper">
        <div className="Opinions__text">
          <q className="title title_h3 Opinions__title">Гордость за&nbsp;достижения компании</q>
          <blockquote className="Opinions__blockquote">
            <p>Гордость вызывают достижения компании&nbsp;&mdash; нам принадлежит более половины всего мотивированного трафика в&nbsp;РФ, и&nbsp;только за&nbsp;2019 год мы&nbsp;выросли вдвое&nbsp;&mdash; и&nbsp;по&nbsp;клиентам, и&nbsp;по&nbsp;сотрудникам.</p>
            <p>Здесь я&nbsp;перестала воспринимать рабочие моменты как личную трагедию. Во&nbsp;многом этому способствует здоровая атмосфера в&nbsp;коллективе&nbsp;&mdash; сначала сообща добиваемся результата и&nbsp;помогаем друг другу, а&nbsp;потом вместе празднуем успехи.</p>
            <p>Ещё очень нравятся тематические тусовки&nbsp;&mdash; я, например, посещаю киноклуб.</p>
            <footer className="Opinions__author">Анна Чайковская, менеджер по продажам</footer>
          </blockquote>
        </div>
        <div className="Opinions__pic Opinions__pic_2">
          <img className="Opinions__img" src={AnnaCh} alt=""/>
        </div>
        </div>
      </TabPanel>
      <TabPanel className="tab-content__item">
        <div className="Opinions__text-wrapper">
        <div className="Opinions__text">
          <q className="title title_h3 Opinions__title">Получилось прокачаться в&nbsp;менеджменте</q>
          <blockquote className="Opinions__blockquote">
            <p>До&nbsp;Appbooster я&nbsp;5&nbsp;лет работал инженером и&nbsp;тим-лидом, но&nbsp;именно здесь получилось по-настоящему прокачаться в&nbsp;менеджменте. С&nbsp;каждым разработчиком раз в&nbsp;3&nbsp;месяца провожу ассессмент, на&nbsp;котором можно прямо сказать если что-то волнует. За&nbsp;последние 3&nbsp;года департамент разработки покинули всего 4&nbsp;человека.</p>

            <p>Продуктовый подход учит делать вещи, которыми пользуются: любой проект начинается с&nbsp;MVP, а&nbsp;на&nbsp;конечный результат влияют все. Каждая идея заносится в&nbsp;бэклог и&nbsp;обсуждается. Горжусь нашей автоматизацией в&nbsp;разработке&nbsp;&mdash; пока ни&nbsp;у&nbsp;кого не&nbsp;встречал ничего подобного.</p>

            <footer className="Opinions__author">Александр Субботин, CTO</footer>
          </blockquote>
        </div>
          <div className="Opinions__pic Opinions__pic_3">
            <img className="Opinions__img" src={SashaS} alt=""/>
          </div>
        </div>
      </TabPanel>
      <TabPanel className="tab-content__item">
        <div className="Opinions__text-wrapper">
        <div className="Opinions__text">
          <q className="title title_h3 Opinions__title">Со&nbsp;мной занимались опытные разработчики</q>
          <blockquote className="Opinions__blockquote">
            <p>Я&nbsp;пришёл в&nbsp;компанию и&nbsp;начал писать код на&nbsp;React Native&nbsp;&mdash; в&nbsp;новой для себя среде. До&nbsp;этого у&nbsp;меня за&nbsp;спиной были курсы и&nbsp;месяц работы в&nbsp;компании с&nbsp;невысокими стандартами в&nbsp;разработке. Из-за этого первое время были трудности с&nbsp;коммуникацией&nbsp;&mdash; требовалось научиться правильно ставить вопросы и&nbsp;рационально оценивать задачи.</p>

            <p>Три месяца со&nbsp;мной занимались опытные разработчики, у&nbsp;которых можно было проконсультироваться в&nbsp;любой момент. Затем я&nbsp;начал работать над клиентской частью во&nbsp;внутреннем стартапе компании.</p>
            <footer className="Opinions__author">Дмитрий Комалов, Frontend-разработчик</footer>
          </blockquote>
        </div>
        <div className="Opinions__pic Opinions__pic_4">
          <img className="Opinions__img" src={DimaK} alt=""/>
        </div>
        </div>
      </TabPanel>
      <TabPanel className="tab-content__item">
        <div className="Opinions__text-wrapper">
        <div className="Opinions__text">
          <q className="title title_h3 Opinions__title">Я&nbsp;научилась проявлять инициативу</q>
          <blockquote className="Opinions__blockquote">
            <p>Самым интересным вызовом для меня стало внедрение гибких методологий в&nbsp;таком подразделении, как HR. Благодаря этому я&nbsp;научилась самостоятельно вести свои проекты и&nbsp;пользоваться task-менеджерами.</p>
            <p>Ещё я&nbsp;научилась проявлять инициативу, так как в&nbsp;нашей компании это действительно приветствуется. Сейчас я&nbsp;провожу для ребят soft skills тренинги, делаю корпоративный мерч с&nbsp;собственным дизайном и&nbsp;даже занимаюсь ремонтом в&nbsp;новых помещениях.</p>
            <p>В&nbsp;нерабочее время мы&nbsp;вместе занимаемся спортом, ходим в&nbsp;кино и&nbsp;языковые клубы, участвуем в&nbsp;конференциях и&nbsp;вообще дружим семьями. Это, наверное, и&nbsp;есть настоящая сплочённость:)</p>
            <footer className="Opinions__author">Алёна Шеховцова, HR-директор</footer>
          </blockquote>
        </div>
        <div className="Opinions__pic Opinions__pic_5">
          <img className="Opinions__img" src={AlyonaP} alt=""/>
        </div>
        </div>
      </TabPanel>
    </Tabs>
  </div>
)
