import React from "react"
import ScrollContainer from 'react-indiana-drag-scroll'
import "./Principles.css"
import Img_Dima from "../../assets/images/Principles__1.jpg"
import Img_Andrey from "../../assets/images/Principles__2.jpg"
import Img_Eugene from "../../assets/images/Principles__4.jpg"
import Img_Happy from "../../assets/images/Principles__3.jpg"
import Img_Nadejda from "../../assets/images/Principles__6.jpg"
import Img_Jenya from "../../assets/images/Principles__5.jpg"
import Img_Walk from "../../assets/images/Principles__6-2.jpg"
import Img_Lera from "../../assets/images/Principles__8.jpg"
import Img_Dasha_Nikita from "../../assets/images/Principles__10.jpg"
import Img_HighFive from "../../assets/images/Principles__9.jpg"
import Img_Games from "../../assets/images/Principles__10-2.jpg"

export default () => {
  const scrollEl = React.createRef()

  const onClick = (dir = 1) => {
    const elem = scrollEl.current.container.current
    const visibleWidth = elem.offsetWidth
    const curScroll = elem.scrollLeft

    elem.scrollTo({
      left: curScroll + visibleWidth * 0.7 * dir,
      behavior: "smooth",
    })
  }

  return (
    <div className="Principles" id="principles">
      <div className="Principles__heading">
        <h2 className="title title_h2 Principles__title">
          Наши принципы
          <button
            className="Principles__btn"
            type="button"
            onClick={() => onClick(-1)}
          >
            &larr;
          </button>
          <button
            className="Principles__btn"
            type="button"
            onClick={() => onClick(1)}
          >
            &rarr;
          </button>
        </h2>
      </div>
      <ScrollContainer className="scroll-container Principles__scroll" ref={scrollEl}>
        <div className="Principles__block Principles__block_1">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Dima} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">01</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Делать&nbsp;&ne;&nbsp;сделать</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">02</span>
              <p>
                Главное&nbsp;&mdash; результат. Действуем проактивно,
                а&nbsp;не&nbsp;реактивно.
              </p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_2">
          <div className="Principles__desc">
            <h3 className="title title_h3">
              Даём друг другу честный и&nbsp;быстрый фидбек
            </h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">01</span>
              <p>Не&nbsp;забываем ругать и&nbsp;хвалить коллег!</p>
            </div>
          </div>
          <span className="Principles__num Principles__num_small">02</span>
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Andrey} alt="" />
          </div>
        </div>

        <div className="Principles__block Principles__block_3">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Eugene} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">03</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Не&nbsp;бывает невозможного</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">03</span>
              <p>
                Мы&nbsp;не&nbsp;говорим&nbsp;&mdash; &laquo;это нельзя
                сделать&raquo;. Мы&nbsp;говорим &laquo;это можно сделать,
                если...&raquo;. Или &laquo;я&nbsp;не&nbsp;знаю, как это сделать,
                но&nbsp;давай спросим...&raquo;
              </p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_4">
          <div className="Principles__desc">
            <h3 className="title title_h3">
              Создаём активы, а&nbsp;не&nbsp;пассивы
            </h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">04</span>
              <p>
                Результат действия-пассива заканчивается сразу после завершения,
                а&nbsp;действие-актив имеет продолжительный эффект.
              </p>
            </div>
          </div>
          <span className="Principles__num Principles__num_small">04</span>
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Happy} alt="" />
          </div>
        </div>

        <div className="Principles__block Principles__block_5">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Nadejda} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">05</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Оперируем фактами</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">05</span>
              <p>Мы&nbsp;не&nbsp;оперируем догадками или мнениями.</p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_6">
          <div className="Principles__desc">
            <h3 className="title title_h3">Пробуй, ошибайся, учись</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">06</span>
              <p>
                Лучше попробовать и&nbsp;ошибиться, чем не&nbsp;сделать вообще.
              </p>
            </div>
          </div>
          <span className="Principles__num Principles__num_small">06</span>
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Jenya} alt="" />
          </div>
        </div>

        <div className="Principles__block Principles__block_7">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Walk} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">07</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Не&nbsp;спорим впустую</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">07</span>
              <p>
                Мы&nbsp;стараемся слышать коллег, допускаем свою неправоту
                и&nbsp;вместо поиска виновных&nbsp;&mdash; ищем лучшие решения.
              </p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_8">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Lera} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">08</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Открыто говорим о&nbsp;проблемах</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">08</span>
              <p>
                Не&nbsp;нужно ничего замалчивать&nbsp;&mdash; проблемы нужно
                обсуждать.
              </p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_9">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Dasha_Nikita} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">09</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Учимся сами, учим других</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">09</span>
              <p>
                Мы&nbsp;постоянно меняемся и&nbsp;развиваемся, используя новые
                практики и&nbsp;инструменты и&nbsp;делимся ими.
              </p>
            </div>
          </div>
        </div>

        <div className="Principles__block Principles__block_10">
          <div className="Principles__desc">
            <h3 className="title title_h3">Не делаем ничего просто так</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">10</span>
              <p>
                Любая задача должна приносить пользу. Не нужно начинать
                выполнение задачи, не поняв зачем она делается.
              </p>
            </div>
          </div>
          <span className="Principles__num Principles__num_small">10</span>
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_HighFive} alt="" />
          </div>
        </div>

        <div className="Principles__block Principles__block_11">
          <div className="Principles__pic">
            <img className="Principles__img" src={Img_Games} alt="" />
          </div>
          <span className="Principles__num Principles__num_small">11</span>
          <div className="Principles__desc">
            <h3 className="title title_h3">Всё в&nbsp;твоих руках</h3>
            <div className="Principles__text">
              <span className="Principles__num Principles__num_large">11</span>
              <p>
                Будь проактивным, находи пути достижения всего, что тебе нужно.
                В&nbsp;том числе от&nbsp;окружающих.
              </p>
            </div>
          </div>
        </div>
      </ScrollContainer>
      {/*<div className="Principles__scrollbar">
        <div className="Principles__scrollbar-thumb"></div>
      </div>*/}
    </div>
  )
}
