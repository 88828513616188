import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import './Reasons.css'
import Button from '../_UI/Button/Button'
import Img from '../../assets/images/Reasons__decor.jpg'

export const Reasons = () => {
  const [tabIndex, setTab] = useState(0)

  useEffect(() => {
    const tabList = document.querySelector('.Reasons .react-tabs__tab-list')
    const tabActive = document.querySelector('.Reasons .react-tabs__tab--selected')
    const tabActiveCoordY = tabActive.offsetTop
    const tabListOverlayOffset = 110
    tabList.scrollTo({
      top: tabActiveCoordY - tabListOverlayOffset,
      left: 0,
      behavior: 'smooth',
    })
  });

  const getRandomTab = () => {
    let rand
    do {
      rand = Math.round(Math.random() * 9);
    } while (rand === tabIndex);
    setTab(rand)
  }

  return (
    <div className="Reasons">
      <div className="Reasons__container">
        <div className="Reasons__content">
          <h2 className="title title_h2 Reasons__title">10 причин работать у&nbsp;нас</h2>
          <Tabs
            className="Reasons__react-tabs"
            selectedIndex={tabIndex}
            onSelect={index => setTab(index)}
          >
            <div className="react-tabs__overlay react-tabs__overlay_top">&nbsp;</div>
            <div className="react-tabs__overlay react-tabs__overlay_bottom">&nbsp;</div>
            <TabList>
                <Tab>01</Tab>
                <Tab>02</Tab>
                <Tab>03</Tab>
                <Tab>04</Tab>
                <Tab>05</Tab>
                <Tab>06</Tab>
                <Tab>07</Tab>
                <Tab>08</Tab>
                <Tab>09</Tab>
                <Tab>10</Tab>
            </TabList>
            <TabPanel>
              <h3 className="title title_h3">Свобода и комфорт</h3>
              <p>Созданная с любовью атмосфера поддержки и творчества позволяет раскрывать потенциал сотрудников на любой роли. Мы ценим твои границы: для некоторых позиций есть опция удалённой работы, а рабочий день в нашем офисе начинается с 9:00 до 12:00.</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Постоянное развитие</h3>
              <p>Совершенствование своих знаний и навыков каждым членом команды позволяет год от года расти всей компании. Для этого тебе доступны поездки на тематические курсы и конференции, а также обширная база знаний внутри компании.</p>
            </TabPanel>
            <TabPanel>
            <h3 className="title title_h3">Мыслить результатом</h3>
            <p>Результат — главный критерий в нашей работе. Чтобы помочь каждому члену компании достичь своего результата, мы поощряем людей быть проактивными, не бояться ошибок и оперировать фактами. Раз в полгода тебя ждёт открытый фидбек от руководителя.</p>
          </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Рост</h3>
              <p>У нас постоянно появляются новые клиенты, задачи, знания, технологии. Наш рост это не только размер офиса или количество людей, но в большей степени рост компетенций и проектов. Хотите принять участие в самой быстрорастущей экономике мира? Присоединяйтесь к нам!</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Постоянно ищем новое</h3>
              <p>Мы ищем наиболее эффективные решения задач и используем самые передовые техники и технологии. Для тебя это возможность следовать трендам и применять новые фреймворки.</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Продуктовая разработка</h3>
              <p>Ты поймёшь как рождаются идеи и как претворяются в жизнь. Увидишь, как они эволюционируют в конечные задачи и превращаются в живые продукты. Сможешь проявлять инициативу, выдвигать гипотезы и видеть результаты своей работы.</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Талантливые профессионалы</h3>
              <p>Мы уделяем большое внимание отбору людей в команду и не снижаем планку требований для наших вакансий. К нам попадают только самые целеустремлённые профессионалы своего дела, в таком коллективе просто не получается работать плохо!</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Работайте так, как вам удобно</h3>
              <p>Удобное кресло или поэнг, стол для работы стоя у панорамного окна или работа удалённо — выбирайте сами.</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Помогаем адаптироваться</h3>
              <p>В первый рабочий день тебя ждёт полноценный онбординг: каждый из наших хедов объяснит, за что отвечает его отдел и с кем тебе лучше всего взаимодействовать. Кроме этого, каждому новичку мы дарим велком-бокс с разными полезными штучками.</p>
            </TabPanel>
            <TabPanel>
              <h3 className="title title_h3">Любим спорт</h3>
              <p>Мы играем в футбол, волейбол и баскетбол. Ходим на кроссфит и катаемся на лошадях. Участвуем и побеждаем в Спартакиаде. С нами ты найдёшь физическую активность по душе.</p>
            </TabPanel>
          </Tabs>
          <Button ClickHandler={getRandomTab} className={'btn_width_fixed Reasons__btn'}>Ещё причина</Button>
        </div>
      </div>
      <div className="Reasons__decor">
        <div className="Reasons__decor-wrapper">
          <img className="Reasons__decor-img" src={Img} alt=""/>
          <div className="Reasons__decor-num">
            <span className="Reasons__decor-num-item">1</span>
            <span className="Reasons__decor-num-item">0</span>
          </div>
        </div>
      </div>
    </div>
  )
}
