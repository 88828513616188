import React from 'react'
import clsx from 'clsx'
import './Button.css'

export default (props) => (
  <button
    className={clsx(
      'btn',
      props.skin ? `btn_${props.skin}` : 'btn_pink',
      props.className
    )}
    type={props.type ? props.type : 'button'}
    onClick={props.ClickHandler}
    disabled={props.disabled}
  >{props.children}
  </button>
)
