import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./FirstScreen.css"
import Logo from "../_UI/Logo/Logo"
import MenuMobileBtn from "../MenuMobile/MenuMobileBtn"
import MenuMobile from "../MenuMobile/MenuMobile"

export default () => {
  const [menuIsOpen, openMenu] = useState(false)

  return (
    <div className="FirstScreen">
      <div className="FirstScreen__container">
        <div className="top-bar">
          <Logo />
          <nav className="nav">
            <ul className="nav__list">
              <li className="nav__item">
                <a className="nav__link" href="/#principles">
                  Принципы
                </a>
              </li>
              <li className="nav__item">
                <a className="nav__link" href="/#opinions">
                  Мнения
                </a>
              </li>
              <li className="nav__item">
                <a className="nav__link" href="/#callbackform">
                  Отправить резюме
                </a>
              </li>
            </ul>
          </nav>
          <MenuMobileBtn onClick={() => openMenu(!menuIsOpen)} />
        </div>
      </div>
      <MenuMobile open={menuIsOpen} onClick={() => openMenu(!menuIsOpen)} />
    </div>
  )
}
