import React from "react"
import './MenuMobile.css'
import Logo from '../_UI/Logo/Logo'
import clsx from "clsx"

export default (props) => {

  const linksHandler = () => {
    props.onClick()
  }

  return (
    <div className={clsx('MenuMobile', props.open ? 'MenuMobile_open' : null)}>
      <div className="MenuMobile__row">
        <div className="MenuMobile__top">
          <Logo skin="light"/>
          <button className="MenuMobile__close" onClick={props.onClick}>&times;</button>
        </div>
        <ul className="MenuMobile__list">
          <li className="MenuMobile__item">
            <a className="MenuMobile__link" onClick={linksHandler} href="/#principles">Принципы</a>
          </li>
          <li className="MenuMobile__item">
            <a className="MenuMobile__link" onClick={linksHandler} href="/#opinions" >Мнения</a>
          </li>
          <li className="MenuMobile__item">
            <a className="MenuMobile__link" onClick={linksHandler} href="/#callbackform">Отправить резюме</a>
          </li>
        </ul>
      </div>
      <div className="MenuMobile__row">
        <div className="MenuMobile__bottom">
          <a
            className="MenuMobile__bottom-link"
            href="https://appbooster.com/"
            target="_blank"
            rel="noopener noreferrer"
          >Основной сайт
          </a>
          <a
            className="MenuMobile__bottom-link"
            href="https://my.appbooster.com/"
            target="_blank"
            rel="noopener noreferrer"
          >Платформа
          </a>
        </div>
      </div>
    </div>
  )
}
