import React from "react"
import "./Jobs.css"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetJobs {
        nodes {
          title
          hhUrl
          id
          description
        }
        totalCount
      }
    }
  `)
  const jobs = data.allGoogleSpreadsheetJobs.nodes
  const jobsCount = data.allGoogleSpreadsheetJobs

  return (
    <div className="Jobs" id="jobs">
      <div className="Jobs__container">
        <div className="Jobs__header">
          <div className="Jobs__header-row">
            <h2 className="title title_h3">Вакансии</h2>
            <span className="Jobs__counter">{jobsCount.totalCount}</span>
          </div>
        </div>
        <div className="jobs-catalog">
          <ul className="jobs-catalog__list">
            {jobs.map(job => (
              <div className="jobs-catalog__block" key={job.id}>
                <div>
                  <p className="title title_h2 jobs-catalog__title">
                    {job.title}
                  </p>
                  {job.description}
                </div>
                <ul className="jobs-catalog__list">
                  <li className="jobs-catalog__item" key={job.id}>
                    <a
                      href={job.hhUrl}
                      className="jobs-catalog__link"
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: "Откликнуться на вакансию" }}
                    />
                  </li>
                </ul>
              </div>
            ))}
          </ul>
          <div className="jobs-catalog__block jobs-catalog__block_placeholder">
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}